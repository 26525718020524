<template>
<div>
    <section style="position:relative;">
        <AdvanceTable ref="advanceTable" url="crm/contact" :height="window.height - 310" :columns="columns" :pageOptions="[50, 100, 200]" :defaultPageSize="50" @clear="clearData"  @edit="openModalForEdit" >
        <template #title>
            {{ $t("Contacts") }}
        </template>
         <template #head(owner_id)="data">
            <div class="mt-1 w-100 text-center text-nowrap" style="padding-bottom: 2px;">
                {{ data.label }}
                <template v-if="data.field.sortable">
                  <feather-icon icon="ChevronUpIcon" :class="{ 'text-primary': !sortDesc && sortBy === data.field.key, 'text-secondary': sortDesc || sortBy !== data.field.key }" />
                  <feather-icon icon="ChevronDownIcon" :class="{ 'text-primary': sortDesc && sortBy === data.field.key, 'text-secondary': !sortDesc || sortBy !== data.field.key }" />
                </template>
              </div>
              <b-form-select :options="ownerList" v-model="ownerId" value-field="id"
                text-field="name" @change="searchOwner(ownerId)">
                <template #first>
                    <b-form-select-option :value="''" ></b-form-select-option>
                </template>
              </b-form-select>
        </template>
        <template #head(organization_id)="data">
            <div class="mt-1 w-100 text-center text-nowrap" style="padding-bottom: 2px;">
                {{ data.label }}
                <template v-if="data.field.sortable">
                  <feather-icon icon="ChevronUpIcon" :class="{ 'text-primary': !sortDesc && sortBy === data.field.key, 'text-secondary': sortDesc || sortBy !== data.field.key }" />
                  <feather-icon icon="ChevronDownIcon" :class="{ 'text-primary': sortDesc && sortBy === data.field.key, 'text-secondary': !sortDesc || sortBy !== data.field.key }" />
                </template>
              </div>
              <b-form-select :options="organizationList" v-model="organizationId" value-field="id"
                text-field="name" @change="searchOrganization(organizationId)">
                <template #first>
                    <b-form-select-option :value="''" ></b-form-select-option>
                </template>
              </b-form-select>
        </template>
        <template #cell(owner_id)="row">
            <span v-for="(item,index) in ownerList" :key="index">
                <span v-if="item.id == row.item.owner_id">{{item.name}}</span>
                <span v-else></span>
            </span>
        </template>
        <template #cell(organization_id)="row">
            {{ organizationMap[row.item.organization_id] ? organizationMap[row.item.organization_id].name : '' }}
        </template>
        <template v-slot:cell(add_time)='row'>
          {{ row.item.add_time | formatTimestamp }}
        </template>
        <template v-slot:cell(update_time)='row'>
          {{ row.item.update_time | formatTimestamp }}
        </template>
        <template #button>
            <b-button variant="info" class="mr-1" @click="openModalForCreate">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">{{ $t("Contact") }}</span>
            </b-button>
        </template>
        </AdvanceTable>
    </section>
    <b-modal
      id="api-log"
      centered
      :title="$t(title)"
      size="lg"
      v-model="isShow"
      hide-footer
    >
     <b-card no-body>
        <b-card-body>
              <b-row >
                  <b-col lg="12" >
                      <b-form-group
                      :label="$t('Contact Name')"
                      label-for="">
                          <el-input
                              v-model="contactinfo.name"
                          />
                      </b-form-group>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col lg="12">
                      <b-form-group
                      :label="$t('Organisation')"
                      label-for="">
                          <el-select filterable v-model="contactinfo.organization_id" style="width: 100%">
                            <el-option v-for="item in organizationList" :key="item.id" :label="item.name + (item.status === 'PENDING' ? ' (' + item.status + ')' : '')" :value="item.id"></el-option>
                          </el-select>
                      </b-form-group>
                  </b-col>
                  <b-col lg="12">
                      <b-form-group
                      :label="$t('Phone')"
                      label-for=""
                      >
                          <el-input
                              v-model="contactinfo.phone"
                              ref="input"
                          />
                      </b-form-group>
                  </b-col>
                  <b-col lg="12">
                      <b-form-group
                      :label="$t('Email')"
                      label-for="">
                          <el-input
                              type="email"
                              v-model="contactinfo.email"
                          />
                      </b-form-group>
                  </b-col>
                  <b-col lg="12">
                      <b-form-group
                      :label="$t('Owner')"
                      label-for="">
                          <el-select filterable v-model="contactinfo.owner_id" style="width: 100%">
                            <el-option v-for="item in ownerList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                          </el-select>
                      </b-form-group>
                  </b-col>
              </b-row>
          <div>
              <div class="w-100 d-flex justify-content-end">
                  <b-button
                      variant="info"
                      class="btn mr-2"
                      @click="isShow=false;$refs.advanceTable.loadList()"
                  >
                      {{ $t('Cancel') }}
                  </b-button>
                  <b-button
                      variant="primary"
                      class="btn"
                      @click="save"
                  >
                      {{ $t('Save') }}
                  </b-button>
              </div>
          </div>
        </b-card-body>
     </b-card>
    </b-modal>
  </div>
</template>

<script>
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import { BModal } from "bootstrap-vue";
import apis from "@configs/apis";
import { fetcher, FetchMethod } from "@/libs/axios";
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from 'moment'
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    AdvanceTable,
    vSelect,flatPickr,
    BModal,moment,
    ToastificationContent
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },

      ownerId:'',
      organizationId:'',
      addTime:'',
      updateTime:'',
      columns: [
        { key: "name", modelName: "name", label: "Contact Name", width: "250", filtertype: "input", sortable: true },
        { key: "organization_id", modelName: "organizationId", label: "Organisation",filtertype: "input", sortable: true },
        { key: "phone", modelName: "phone", label: "Phone", filtertype: "input",width: "250", sortable: true },
        { key: "email", modelName: "email", label: "Email", width: "250", filtertype: "input", sortable: true },
        { key: "owner_id", modelName: "ownerId", label: "Owner", sortable: true },
        { key: "add_time", modelName: "addTime", label: "Create Date",width: "200", filtertype: "datetime", sortable: true },
        { key: "update_time", modelName: "updateTime", label: "Update Date", filtertype: "datetime", width: "200", sortable: true },
      ],
      title:'Add Contact',
      contactinfo:{},
      isShow:false,
      ownerList:[],
      organizationList:[],
      organizationMap: {},
      sortBy: "",
      sortDesc: true,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.getOwner()
    this.getOrganization()
  },
  methods: {
    getUTC(t) {
        const data = new Date(+new Date(t) + 8 * 3600 * 1000).toISOString()
        return data
    },
    clearData(){
        this.ownerId  = ''
        this.organizationId= ''
        this.addTime = ''
        this.updateTime = ''
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    searchOwner(val){
        this.organizationId= ''
        this.addTime = ''
        this.updateTime = ''
        this.$refs.advanceTable.loadList({'ownerId':val});
    },
    searchOrganization(val){
        this.ownerId  = ''
        this.addTime = ''
        this.updateTime = ''
        this.$refs.advanceTable.loadList({'organizationId':val});
    },
    openModalForCreate() {
        Object.keys(this.contactinfo).forEach(key => (this.contactinfo[key] = ''));
        this.isShow = true
        this.title = 'Add Contact'
    },
    openModalForEdit(item) {
        this.contactinfo = item
        this.isShow = true
        this.title = 'Edit Contact'
    },
    selOwner(val){
        this.contactinfo.owner_id = val.id
    },
    selOrganization(val){
        this.contactinfo.organization_id = val.id
    },
    async getOwner(){
        try {
            const response = await fetcher(
                apis.crmGetUser,
                FetchMethod.GET,
                {pageSize: 1000000}
            );
            this.ownerList = response.data
        } catch (e) {}
    },
    async getOrganization(){
        try {
            const response = await fetcher(
                apis.crmGetOrganization,
                FetchMethod.GET,
                {pageSize: 1000000, sortBy: 'name', sortDirection: 'asc'}
            );
            this.organizationList = response.data
            this.organizationMap = Object.freeze(Object.fromEntries(
              this.organizationList.map(e => [e.id, e])
            ));
        } catch (e) {}
    },
    async save(){
        // const params =this.title == 'Add Contact'?apis.crmPostContacts:apis.crmPutContact+'/'+this.contactinfo.id
        // const methodType = this.title == 'Add Contact'?FetchMethod.POST:FetchMethod.PUT
        if (this.title == 'Add Contact') {
            try {
                const response = await fetcher(
                    apis.crmPostContacts,
                    FetchMethod.POST,
                    {
                        "name": this.contactinfo.name,
                        "phone": this.contactinfo.phone,
                        "email": this.contactinfo.email,
                        "owner_id": Number(this.contactinfo.owner_id),
                        "organization_id": Number(this.contactinfo.organization_id)
                    }
                );
                this.$refs.advanceTable.loadList();
                this.isShow = false
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Create Contact Success',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
            })
            } catch (e) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: e.message,
                    icon: 'XIcon',
                    variant: 'danger',
                    },
            })
        }
        }else{
            try {
                const response = await fetcher(
                    apis.crmPutContact+'/'+this.contactinfo.id,
                    FetchMethod.PUT,
                    {
                        "name": this.contactinfo.name,
                        "phone": this.contactinfo.phone,
                        "email": this.contactinfo.email,
                        "owner_id": Number(this.contactinfo.owner_id),
                        "organization_id": Number(this.contactinfo.organization_id)
                    }
                );
                this.$refs.advanceTable.loadList();
                this.isShow = false
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Update Contact Success',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
            })
            } catch (e) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: e.detail,
                    icon: 'XIcon',
                    variant: 'danger',
                    },
            })
            }
        }
    },
  }
};
</script>

<style scoped></style>
